<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import { LogOutIcon } from 'vue-feather-icons';
import Notify from '@/shared/utils/notify';
import Profile from '../../../shared/components/Profile.vue';

export default {
  name: 'layout',
  components: {
    Profile,
    LogOutIcon,
  },
  data() {
    return {
      getToken,
      destroyToken,
      image: '/media/user/profile.svg',
      role: '',
      selectedLang: 'ro',
      languages: [
        {
          title: 'ro',
        },
        {
          title: 'en',
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$router.push({ name: 'login' });
      destroyToken();
    },
    setLanguage(lang) {
      this.selectedLang = lang;
      window.localStorage.setItem('language', lang);
      this.$router.go();
    },
    openProfile() {
      const openProfile = document.querySelector('.profile-modal');
      openProfile.classList.toggle('show');
      if (openProfile.classList.value.includes('hide')) {
        openProfile.classList.toggle('hide');
      } else {
        setTimeout(() => {
          openProfile.classList.toggle('hide');
        }, 300);
      }
    },
    getProfile() {
      const getProfile = '/WebGetProfile.php';
      const data = new FormData();
      fetch(server + getProfile, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        } else if (json.image) {
          this.image = json.image;
        }
      });
    },
  },
  beforeMount() {
    this.getProfile();
    this.role = window.localStorage.getItem('role');
    const currentLang = window.localStorage.getItem('language');
    this.selectedLang = currentLang ?? 'en';

    if (!currentLang) {
      this.setLanguage('en');
    }
    if (getToken() === null) {
      this.$router.push({ name: 'login' });
    }
  },
};
</script>
<template lang="html">
  <div class="home">
    <b-row v-if="getToken().length !== 0">
      <b-col class="menu">
        <div class="logo">
          <img src="/media/logo.svg" alt="">
        </div>
        <router-link to="/">
          <div :class="'menu-item ' + ($route.name == 'home' ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="estate" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.HOME") }}
            </div>
          </div>
        </router-link>
        <router-link :to="role.includes('admin')  ? '/management/department/0'
         : '/management/group/0'">
          <div :class="'menu-item ' + ($route.name.includes('management') ? 'active' : '')">
            <div class="menu-item-icon">
              <img src="/media/Kanban.svg" alt="">
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.MANAGEMENT") }}
            </div>
          </div>
        </router-link>

        <router-link to="/categories">
          <div :class="'menu-item ' + ($route.name.includes('tags') ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="presentation-check" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.TAGS") }}
            </div>
          </div>
        </router-link>

        <router-link to="/ai-cards">
          <div :class="'menu-item ' + ($route.name.includes('ai_cards') ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="file-check-alt" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.AI_CARDS") }}
            </div>
          </div>
        </router-link>
        <router-link to="/content">
          <div :class="'menu-item ' + ($route.name.includes('content') ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="robot" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.CONTENT") }}
            </div>
          </div>
        </router-link>
        <router-link to="/file-ai">
          <div :class="'menu-item ' + ($route.name.includes('file_ai') ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="file-import" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.FILE_AI") }}
            </div>
          </div>
        </router-link>
        <router-link to="/news">
          <div :class="'menu-item ' + ($route.name.includes('new') ? 'active' : '')">
            <div class="menu-item-icon">
              <img src="/media/Newspaper.svg" alt="">
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.NEWS") }}
            </div>
          </div>
        </router-link>

        <router-link to="/mentors" v-if="role.includes('admin')">
          <div :class="'menu-item ' + ($route.name === 'mentors' || $route.name === 'mentor_details'
           ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="users-alt" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.EMPLOYED") }}
            </div>
          </div>
        </router-link>
        <div class="menu-separator">
        </div>

<!--        <router-link to="/learning-sets">
          <div :class="'menu-item ' + ($route.name.includes('learning') ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="presentation-check" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.LEARNING_SET") }}
            </div>
          </div>

        </router-link>
        <router-link to="/tests">
          <div :class="'menu-item ' + ($route.name == 'tests' ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="file-check-alt" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.TESTS") }}
            </div>
          </div>
        </router-link>
        <router-link to="/polls">
          <div :class="'menu-item ' + ($route.name == 'polls' ? 'active' : '')">
            <div class="menu-item-icon">
              <unicon name="question-circle" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.POOLS") }}
            </div>
          </div>
        </router-link>

        <div class="menu-separator">
        </div>

        <router-link to="/">
          <div class="menu-item">
            <div class="menu-item-icon">
              <unicon name="credit-card" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.PAYMENTS_AND_SUBSCRIPTIONS") }}
            </div>
          </div>
        </router-link>-->
<!--        <div class="menu-separator">
        </div>
        <router-link to="/affiliation">
          <div :class="'menu-item ' + ($route.name === 'affiliation' ?
            'active' : '')">
            <div class="menu-item-icon">
              <unicon name="credit-card" width="16" height="16" fill="#1694D0"></unicon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.AFFILIATION_PROGRAM") }}
            </div>
          </div>
        </router-link>-->
        <a v-on:click="logout">
          <div class="menu-item logout">
            <div class="menu-item-icon">
              <log-out-icon color="#1694D0" class="custom-class"></log-out-icon>
            </div>
            <div class="menu-item-text">
              {{ $t("GENERAL.LOGOUT") }}
            </div>
          </div>
        </a>
      </b-col>
      <b-col class="child">
        <b-row>
          <b-col class="p-0">
            <div class="top-item">
             <div style=";display: inline-flex; width: calc(100% - 128px); align-items: center;
             vertical-align: center">
               <div class="bulb-container">
                 <img src="/media/LightbulbFilament.svg" alt="">
               </div>
               <div class="subhead-reg-14 basic-2" style="width: calc(100% - 54px);
               margin-right: 12px">
                 <div v-if="$route.name == 'home'">
                   {{ $t("GENERAL.INFO_HOME") }}
                 </div>
                 <div v-if="$route.name.includes('management')">
                   {{ $t("GENERAL.INFO_MANAGEMENT") }}
                 </div>
                 <div v-if="$route.name.includes('tags')">
                   {{ $t("GENERAL.INFO_TAGS") }}
                 </div>
                 <div v-if="$route.name.includes('ai_cards')">
                   {{ $t("GENERAL.INFO_CARDS") }}
                 </div>
                 <div v-if="$route.name.includes('content')">
                   {{ $t("GENERAL.INFO_CONTENT") }}
                 </div>
                 <div v-if="$route.name.includes('new')">
                   {{ $t("GENERAL.INFO_NEWS") }}
                 </div>
                 <div v-if="$route.name === 'mentors' || $route.name === 'mentor_details'">
                   {{ $t("GENERAL.INFO_MENTORS") }}
                 </div>
                 <div v-if="$route.name === 'affiliation'">
                   {{ $t("GENERAL.INFO_AFFILIATION") }}
                 </div>
               </div>
             </div>
              <div style="width: 128px;display: inline-flex;float: right;
              vertical-align: center">
                <div>
                  <b-dropdown style="background: #F1F3F6;
               border-radius: 8px; margin-right: 12px; margin-top: 4px">
                    <div slot="button-content" style="background-color: transparent!important;"
                         :class="selectedLang ? 'selected' : 'unselected'">
                      <div v-if="selectedLang !== null" class="d-inline-block">
                        <div class="default-color headline-regular-12">
                          {{ selectedLang.toUpperCase() }}

                          <unicon name="angle-down"
                                  width="18" height="18" fill="#1694D0"/>
                        </div>
                      </div>
                    </div>
                    <b-dropdown-item
                        :disabled="language.disabled"
                        @click="setLanguage(language.title)"
                        v-for="language in languages"
                        :key="language.title"
                    >
                      <div>
                        <div class="d-inline-block">
                          <div class="basic-1 headline-regular-16">
                            {{ language.title.toUpperCase() }}
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="profile-icon" v-on:click="openProfile">
                  <img :src="image" alt="">
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
        <div class="child-container">
          <router-view/>
        </div>
      </b-col>
    </b-row>

    <div class="profile-modal hide">
      <Profile/>
    </div>
  </div>

</template>
<style lang="scss">
.menu.col {
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  width: 237px;
  max-width: 237px;
  min-height: 100vh;
  padding: 24px 8px 8px;
}
.profile-icon{
  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 50%;
  img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}

.home {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
  width: 100vw;
  min-height: 100vh;
  background-color: #EBECF0;
  overflow: hidden;
}

.home .row {
  margin: 0;
}

.logo img {
  margin-left: 16px;
  margin-top: 8px;
  margin-bottom: 48px;
  height: 40px;
}

.menu-item {
  display: inline-flex;
  width: 100%;
  padding: 12px 16px;
}

.menu-item.active {
  background: #E8F4FA;
  border-radius: 8px;
}

.menu-item.active .menu-item-text {
  color: #1694D0;
}
.top-item .bulb-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px!important;
  height: 30px;
  background: #FDAB3D;
  border-radius: 50%;
  margin-right: 12px;
}
.top-item .dropdown{
  color: #1694D0;
  button, button:hover, .btn-secondary{
    background: #F1F3F6!important;
  }
  .dropdown-toggle::after{
    display: none;
  }
}
.top-item .dropdown-menu {
  min-width: 100px!important;
  left: -26px!important;
}

.menu-item-text {
  display: inline-flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
}

a:hover {
  text-outline: none !important;
  color: #22232F !important;
  text-decoration: none !important;
}

a:hover .menu-item {
  border: none !important;
}

.menu-item-icon {
 // width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline-flex;
}

.menu-item-icon .unicon svg {
  height: 16px;
  margin-top: -8px
}

.menu-item-icon svg {
  height: 16px;
}

.menu-item.logout {
  position: fixed;
  bottom: 0;
  left: 8px;
  width: 221px;
}

.menu-item.logout:hover {
  cursor: pointer;
}

.menu-separator {
  height: 40px;
}

.child.col {
  padding: 0 !important;
  position: relative;
}

.form-control-2:hover {
  border: 1px solid #5261FF;
}

.form-floating-2:focus-visible label {
  color: #5261FF;
}

.form-control-2:focus-visible {
  border: 1px solid #5261FF;
  box-shadow: none;
  outline-width: 0;
}

.form-control-2 {
  padding: 12px !important;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 43px !important;
}

select.form-control-2 {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 58px !important;
  -webkit-appearance: none;
  padding-left: 59px !important;
}

.form-floating-2 img {
  position: absolute;
  top: 38px;
  left: 13px;
  width: 35px;
  height: 34px;
}

textarea.form-control-2 {
  height: 83px !important;
}

.form-control-2:placeholder-shown {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #BDBDBD;
}

.form-floating-2 label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  padding: 0 12px !important;
  margin-bottom: 6px !important;
  position: unset;
}

.form-floating-2 {
  position: relative;
}

/*table*/

.table-departaments {
  background: linear-gradient(147.42deg, rgba(64, 72, 93, 0.4) 6.32%,
    rgba(96, 106, 130, 0.4) 92.25%), #FBFBFB;
  background-blend-mode: soft-light, normal;
  border: 1px solid #D9DBE3;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
  border-radius: 8px;
  margin-top: 20px;
  --bs-table-accent-bg: none;
}

.table-departaments thead, .table-departaments thead tr {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #9A9FA7 !important;
  background: transparent !important;
  border-bottom: 1px solid #D9DBE3;
  width: 100%;

}

.table-departaments thead tr th {
  color: #9A9FA7 !important;
  background: transparent !important;
  border-bottom: 1px solid #D9DBE3;
  border-bottom-color: #D9DBE3;
}

.table-departaments tbody, .table-departaments tbody tr {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #22232F !important;
  background: transparent !important;
  border-bottom: 1px solid #D9DBE3;
  --bs-table-accent-bg: none !important;
}

.table-departaments td, .table-departaments th {
  padding: 15px !important;
}

.table-departaments .departament-image img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.table-departaments .table-departaments-actions img {
  height: 24px;
  width: 24px;
}

.table-departaments .table-departaments-actions img:first-child {
  margin-right: 16px;
  height: 18px;
  width: 18px;
}

.table-departaments .table-departaments-read-more .dropdown button {
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

/*end-table*/

.top-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  height: 64px;
  padding: 8px;
  position: absolute;
  top: 16px;
  left: 24px;
  width: calc(100% - 48px);
  z-index: 1;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  display: flex;
}

.back-item {
  padding: 15.5px 16px 15.5px 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  cursor: pointer;
}

.back-item #text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.back-item img {
  height: 17px;
  width: 17px;
  margin-right: 8px;
}

.dropdown-toggle::after {
  margin-top: 9px;
}

.dropdown-toggle {
  border: none !important;
  box-shadow: none !important;
}

</style>
