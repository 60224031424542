<template>
    <b-button
      v-if="!notRendered"
      :id="id"
      :class="buttonStyleClasses"
      :variant="variantComputed"
      :size="sizeComputed"
      :active="active"
      :disabled="disabled"
      :block="block"
      :to="to"
      v-on:click="clickCallback">
      <slot></slot>
    </b-button>
</template>
<style lang="scss">

.btn-primary {
  background: #1694D0!important;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  color: #FFFFFF!important;
  padding: 12px!important;
  height: 40px!important;
  border: none!important;
}
.btn-primary:hover{
  color: #FFFFFF!important;
}
.btn-info {
  background: #F1F3F6!important;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  color: #828282!important;
  padding: 12px!important;
  height: 40px!important;
  border: none!important;
}

.btn-info:hover {
  color: #828282!important;
}
.btn-secondary {
  background: #FDAB3D;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  color: #FFFFFF!important;
  padding: 12px!important;
  height: 40px!important;
  border: none!important;
}

.btn-secondary:hover {
  color: #FFFFFF!important;
}
.btn-secondary.inactive {
  border: 1px solid #FDAB3D!important;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  background: #FFFFFF!important;
  padding: 12px!important;
  height: 40px!important;
  color: rgba(51, 51, 51, 1) !important;
}
.btn-primary img{
  margin-right: 12px;
  height: 16px;
  width: 16px;
  margin-top: -2px;
}

.btn-light{
  background: white!important;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25)!important;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  border: 1px solid #1694D0!important;
  color: #1694D0!important;
  display: inline-flex!important;
  padding: 12px!important;
  height: 40px;
  justify-content: center;
}

.btn-light:hover {
  color: #1694D0!important;
}
.btn-inactive{
  background: #F2F2F2!important;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25)!important;
  border-radius: 8px!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center;
  padding: 12px!important;
  color: #BDBDBD!important;
  border: none!important;
  display: inline-flex!important;
}
.btn-warning{
  box-sizing: border-box;
  border-radius: 8px!important;
  height: 41px!important;
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  color: #FFFFFF!important;
  background: #FDD49D!important;
  border-color: transparent!important;

}
.btn-danger{
  box-sizing: border-box;
  border-radius: 8px!important;
  height: 41px!important;
  font-family: Inter!important;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center!important;
  color: #FFFFFF!important;
  background: #dc3545!important;
  border-color: transparent!important;
}
.btn-success{
  background: #7FDCB3!important;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25)!important;
  border-radius: 8px!important;
  color: white!important;
  font-family: Inter;
  font-style: normal!important;
  font-weight: normal!important;
  font-size: 14px!important;
  line-height: 17px!important;
  text-align: center;
  padding: 12px!important;
  border: none!important;
}

.btn-success img,.btn-warning img,.btn-success .unicon,.btn-warning .unicon{
  margin-right: 12px;
  height: 16px;
  width: 16px;
  margin-top: -4px;
}

.btn-light img,.btn-warning img,.btn-inactive img{
  margin-right: 12px;
  height: 16px;
  width: 16px;
  display: inline-flex;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 6px!important;
  font-size: 14px!important;
  height: 32px;
  margin-top: -6px!important;
}
.add_news_button {
  position: relative;
}
.add_news_button button{
  position: absolute;
  right: 0;
}
</style>
<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

const VARIANTS = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  'link',
  'hover',
  'custom-link',
  'inactive',
];
const SIZES = {
  small: 'sm',
  medium: '',
  large: 'lg',
};

export default {
  name: 'BaseButton',
  props: {
    notRendered: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Set a custom css class to the button
    customClass: String,
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: Number,
    title: {
      type: String,
      default: 'primary',
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    to: String,
  },
  computed: {
    buttonStyleClasses() {
      const classes = [];
      if (this.customClass) {
        classes.push(this.customClass);
      }
      if (this.spinner) {
        classes.push('spinner spinner-right');
      }
      return classes;
    },
    variantComputed() {
      const variant = VARIANTS.includes(this.variant) ? this.variant : 'primary';
      if (this.outline) {
        return `outline-${variant}`;
      }
      return variant;
    },
    sizeComputed() {
      const size = Object.keys(SIZES).includes(this.size) ? this.size : 'default';
      return SIZES[size];
    },
  },
  methods: {
    clickCallback() {
      if (this.spinner) {
        return;
      }
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }

        if (this.callbackAction) {
          this.$store.dispatch(this.callbackAction, this.callbackParams);
        }
      }
    },
  },
};
</script>
