const locale = {
  GENERAL: {
    ALL: 'Toate',
    BACK: 'Înapoi',
    COPY_LINK: 'Copiază link-ul',
    COPIED_LINK: 'Link copiat',
    DEPARTAMENT: 'Departament',
    SAVE: 'Salvează',
    BASIC_INFO: 'Informații de bază',
    PERFORMANCE: 'Statistici de Performanță',
    DELETE: 'Șterge',
    DOWNLOAD_EXCEL: 'Descarcă excel-ul',
    CHANGE: 'Schimbă',
    HOME: 'Home',
    MANAGEMENT: 'Organizare',
    CONTENT: 'Asistentul AI',
    FILE_AI: 'Fișiere AI',
    TAGS: 'Noțiuni cu AI',
    NEWS: 'Adăugare noutăți',
    EMPLOYED: 'Alocare Mentori',
    LOGOUT: 'Deconectare',
    AFFILIATION_PROGRAM: 'Program de recompense',
    AI_CARDS: 'Creare noțiuni',
    DRAG_AND_DROP_FILES: 'Trage fișierele aici',
    QUESTION: 'Întrebarea',
    CARDS_LOWERCASE: 'carduri de învățare',
    SAVED_SUCCESSFULLY: 'Salvat cu success',
    ACCESS_TO_ALL: 'Oferă acces general pentru toți utilizatorii',
    ACCESS_FOR_ALL: 'Acces pentru toți utilizatorii',
    ACCESS_FOR_ME: 'Acces doar pentru mine',
    PASSWORD_MUST_CONTAIN: 'Parola trebuie sa contină:',
    AR_LEAST_6_CHARACTERS: 'cel puțin 6 caractere',
    AR_LEAST_ONE_LETTER: 'cel puțin o literă',
    AR_LEAST_ONE_NUMBER: 'cel puțin un număr',
    AR_LEAST_ONE_TOKEN: 'cel puțin un simbol',
    EMAIL_CODE: 'Codul primit pe email',
    NOT_SELECTED: 'Neselectat',
    INFO_HOME: 'Află secretele învățării profunde integrate de EdNeos urmărind acest video informativ. [link video]',
    INFO_MANAGEMENT: 'Creează o grupă dedicată beneficiarilor instruirii tale, înregistrându-i cu numele și email-ul lor și monitorizează rezultatele în timp real.',
    INFO_TAGS: 'Adaugă un domeniu, dezvoltă o noțiune, creează carduri de învățare și explorează în continuare, toate cu ajutorul inteligenței artificiale.',
    INFO_CARDS: 'Introdu o noțiune și primești instant un set de carduri de învățare. Vei găsi domeniul și noțiunea create în secțiunea “Creare noțiune”.',
    INFO_CONTENT: 'Pune o întrebare folosind comanda vocală, iar asistentul nostru AI te va ajuta să salvezi răspunsul pe cardul de învățare aferent noțiunii.',
    INFO_NEWS: 'Menține o comunicare constantă cu audiența ta, programând trimiterea automată a notificărilor în momentele cheie.',
    INFO_MENTORS: 'Menține o comunicare constantă cu audiența ta, programând trimiterea automată a notificărilor în momentele cheie.',
    INFO_AFFILIATION: 'Mulțumim pentru contribuția ta! Pentru a exprima recunoștința noastră, te așteaptă o serie de recompense și beneficii pregătite special pentru tine.',
    ACCESS_TO_PREMIUM: 'Oferă acces carduri Premium și câștigă bani',
    ACCESS_FOR_ALL_TO_PREMIUM: 'Acces pentru toți utilizatorii (minim 300 carduri de învățare)',
    EVALUATION: 'Evaluare',
    VIEW: 'Vezi',
    QUESTIONS: 'Întrebări',
    MEMORIZATION: 'Memorization',
  },
  NEWS: {
    ADD_NEWS: 'Adaugă anunț',
    PUBLISHING_DATE: 'Data și ora publicării',
    FIELDS: {
      DEPARTAMENT: 'Gestionare acces pentru grupuri create în Organizare:',
      GROUP: 'Grupă',
      TITLE: 'Titlu (maxim 200 caractere)',
      DESCRIPTION: 'Descriere (maxim 500 caractere)',
      IMAGES: 'Imagini',
      LINK: 'Your URL',
    },
  },
  DEPARTAMENTS: {
    PER_MONTH: 'Pe lună',
    LIST: 'Listă Departamente',
    LIST_GROUP: 'Listă grupe',
    ADD_GROUP: 'Adaugă grupă',
    ADD_STUDENT: 'Adaugă angajat',
    EDIT_STUDENT: 'Editează angajat',
    STUDENTS_SUCCESS: 'Angajat Success',
    ADD_DEPARTAMENT: 'Adaugă departament',
    GROUPS: 'Grupe',
    GROUP: 'Grupă',
    STUDENTS: 'Angajati',
    ADD_NEW_USER: 'Adauga user nou',
    PROGRESS: 'Progress',
    LEARNING_STAGES: 'Etape de învățare',
    NO: 'No',
    STUDENT: 'Angajat',
    RAPIDITY: 'Rapiditate',
    ACCURACY: 'Acuratețea',
    MEMORIZATION: 'Nivel de Învățare',
    ELEMENTARY: 'Începător',
    MEDIUM: 'Intermediar',
    EXPERT: 'Expert',
    APPLICATION: 'Teorie Aplicată',
    LIFEHACK: 'Aplicare în viața noastră',
    COMPLETE_AND_SAVE: 'Completează si salvează',
    IMPORT_STUDENTS: 'Import angajați',
    FIELDS: {
      TITLE: 'Titlu',
      DESCRIPTION: 'Descriere',
      ADMIN: 'Administrator',
      SEARCH: 'Caută',
    },
  },
  EMPLOYEE: {
    EMPLOYEE: 'Adaugă mentor',
    ADD_EMPLOYEE: 'Adaugă mentor',
    NO: '№',
    NAME: 'Nume',
    DATE: 'Data',
    EMAIL: 'Email',
    STATUS: 'Status',
    CHAT: 'Mesaj',
    MORE: 'Mai mult',
    ACTIVE: 'Activ',
    INACTIVE: 'Inactiv',
    FIELDS: {
      SEARCH: 'Cauta',
      LAST_NAME: 'Nume',
      FIRST_NAME: 'Prenume',
      EMAIL: 'Email',
      TYPE: 'Tip',
    },
  },
  ERRORS: {
    DEFAULT_ERROR_MESSAGE: 'A apărut o eroare',
    AUTH_REQUIRED: 'Autentificare necesară',
    AUTH_INVALID: 'Autentificare invalidă. Încearcă din nou!',
    TOKEN_INVALID: 'Token invalid',
    AUTH_FAILED: 'Autentificare eșuată.',
    NO_GROUPS: 'Nu au fost găsite grupe!',
    FILE_FORMAT: 'Fisierul nu are formatul corect!',
    FILE_EMPTY: 'Fisierul nu conține nici o linie!',
  },
  AUTH: {
    LOGIN_TO_EDNEOS: 'Autentificare în EdNeos',
    LOGIN_BUTTON: 'Autentificare',
    FORGOT: 'Ai uitat ',
    PASSWORD: 'parola?',
    DONT_HAVE_ACCOUNT: 'Nu ai un cont?',
    CREATE_ACCOUNT: 'Creează cont',
    LOGIN_WITH_GOOGLE: 'Autentificare cu Google',
    LOGIN_ERROR: 'Ne pare rău, a apărut o eroare!',
    RESET_PASSWORD: 'Resetare parolă',
    RESET_PASSWORD_INFO: 'Confirmarea resetării parolei va fi trimisă pe adresa ta de Email',
    SEND_INSTRUCTIONS: 'Instrucțiune trimisă',
    CHECK_INBOX: 'Instrucțiunile au fost trimise pe emailul tău',
    CHECK_INBOX_INFO: 'Instrucțiunile au fost trimise pe emailul tău',
    TRY_AGAIN: 'Încearcă din nou',
    OOPS: 'Oh!Ceva nu a funcționat cum trebuie',
    OOPS_INFO: 'TNu există un cont asociat acestui email',
    CREATE_PASSWORD: 'Creează o parolă',
    CREATE_PASSWORD_INFO: 'Noua parolă trebuie să fie diferită',
    ERROR_PASSWORD: 'Parole diferite!',
    SUCCES: 'Perfect! Totul a mers bine.',
    SUCCES_INFO: 'Noua ta parolă a fost salvată cu succes.',
    CREATE_MENTOR_ACCOUNT: 'Creează un Cont de Mentor/Profesor',
    CREATE_MENTOR_ACCOUNT_WITH_GOOGLE: 'Înregistrează-te cu Google',
    MENTOR_ACCOUNT: 'Cont Mentor/Profesor',
    MENTOR_ACCOUNT_DESCRIPTION: 'Pasionat de predare? EdNeos transformă pasiunea ta în impact, oferindu-ți unelte pentru o predare care lasă urme adânci și durabile.',
    MENTOR_INFO_1: 'Îmbunătățirea performanței angajaților prin monitorizare continuă',
    MENTOR_INFO_2: 'Suport în crearea conținutului educațional interactiv cu ajutorul AI',
    MENTOR_INFO_3: 'Soluția fiabilă de consolidare și protejare a cunoștințelor pentru cursanți',
    MENTOR_INFO_4: 'Utilizează învățarea interactivă și flashcard-urile cu recunoaștere vocală',
    SCHOOL_ACCOUNT: 'Cont Instituțional',
    SCHOOL_ACCOUNT_DESCRIPTION: 'Susținem instituțiile care aspiră la integrarea tehnologiei în educație, pentru o învățare profundă și de lungă durată.',
    SCHOOL_INFO_1: 'Economisirea timpului și a costurilor de formare',
    SCHOOL_INFO_2: 'Instrumente eficiente de consolidare și protejare a cunoștințelor',
    SCHOOL_INFO_3: 'Monitorizarea inovatoare a progresului educațional al cursanților',
    SCHOOL_INFO_4: 'Utilizează învățarea interactivă și flashcard-urile cu recunoaștere vocală',
    SUBMIT_FOR_SCHOOL_ACCOUNT: 'Înregistrează-te pentru Contul Instituției',
    USER_ACCOUNT: 'Cont Utilizator',
    USER_ACCOUNT_DESCRIPTION: 'Vrei să te asiguri că tot ceea ce înveți rămâne bine întipărit în minte? Descarcă aplicația EdNeos și descoperă soluția ta fiabilă de consolidare și protejare a cunoștințelor!',
    USER_INFO_1: 'Retenția și consolidarea cunoștințelor pe termen lung',
    USER_INFO_2: 'Acces la un sistem de învățare auto-personalizat',
    USER_INFO_3: 'Eficiență și autonomie în gestionarea cunoștințelor',
    USER_INFO_4: 'Utilizează AI și flashcard-uri cu recunoaștere vocală pentru explorare',
    DOWNLOAD_ON_APP_STORE: 'Descarcă din App Store',
    DOWNLOAD_ON_GOOGLE_PLAY: 'Descarcă de pe Google Play',
    AGREE: 'Acceptare',
    TERMS: 'Termeni și condiții, Politica de Confidențialitate.',
    ALREADY_HAVE_ACCOUNT: 'Deja ai un cont?',
    CREATE_SCHOOL_ACCOUNT: 'Înregistrare pentru Cont Instituțional',
    SING_UP: 'Înscrie-te',
  },
  USER: {
    ACCOUNT_SETTINGS: 'Setări cont',
    EXTRAS: 'Extras',
    INVITE_FRIENDS_ON_EDNEOS: 'Invită prieteni la EdNeos',
    EDNEOS_TIPS: 'Sfaturi EdNeos',
    SPECIAL_OFFER: 'OFERTĂ SPECIALĂ!',
    SPECIAL_OFFER_DETAILS: 'Distribuie linkul în orice modalitate și câștigă 10% din orice plată pe care o va face acest utilizator timp de 1 an.',
    UPGRADE_NOW: 'Începe acum!',
    MY_BENEFITS: 'Beneficiile mele',
    FEEDBACK: 'Feedback',
    SETTINGS: 'Setări',
    MY_PROFILE: 'Profilul meu',
    MANAGE_ACCOUNT: 'Administrează contul',
    TERMS_AND_CONDITIONS: 'Consimțământul termenilor și condițiilor',
    PRIVACY_POLICY: 'Politica de confidențialitate',
    DELETE_ACCOUNT: 'Șterge contul',
    REMOVE_FROM_GROUP: 'Elimină din grupă',
    FIELDS: {
      EMAIL: 'Email',
      PASSWORD: 'Parolă',
      PASSWORD_CONFIRM: 'Confirmă parola',
      FULL_NAME: 'Nume complet',
    },
  },
  TAGS: {
    CATEGORIES: 'Domenii',
    CATEGORY: 'Domenii',
    SELECT_CATEGORY: 'Alege un domeniu',
    SELECT_TAG: 'Select Subject',
    ADD_CATEGORY: 'Adaugă un domeniu',
    ADD_CARD: 'Adaugă un card de învățare',
    TAGS: 'Noțiuni',
    TAG: 'Noțiune',
    ADD_TAG: 'Adaugă noțiune',
    FLASH_CARD: 'Flash Card',
    TEXT_ANSWER: 'Răspuns scris',
    VOICE_ANSWER: 'Răspuns cu voce',
    MULTIPLE_ANSWER: 'Răspunsuri multiple',
    ADD_ANSWER: 'Adaugă răspuns',
    ANSWER_RIGHT: 'Adaugă răspunsul corect',
    ANSWER_WRONG: 'Adaugă răspuns greșit',
    CARDS: 'Card de învățare',
    GENERATE_AI_CARDS: 'EdNeos este pregătit să creeze un subiect, împreună cu cardurile de învățare, bazat exclusiv pe cererea ta. Hai să începem!',
    HAVE: 'Ai',
    FREE_TRIAL: 'Credite pentru Texte Generat de AI',
    WHAT_SUBJECT_WOULD_YOU_LIKE_TO_LEARN: 'Ce subiect ai vrea să înveți?',
    I_THINK: 'Îți voi propune imediat un subiect și o categorie',
    SUBJECT: 'Subiect',
    GENERATE_10_CARD: 'Generează 10 fișe',
    CARDS_GENERATED: 'Voi crea în maxim 30 de secunde 10 carduri de învățare, o noțiune și o categorie. Le puteți găsi în meniu la Creare noțiuni.',
    CREATE_MANUAL: 'Crează manual',
    GENERATE_WITH_AI: 'Generează cu AI',
    SELECT_TOPICS: 'Subiecte selectate',
    SELECT_CARD_TYPE: 'Alege tipul de card',
    ALL_STEPS: 'Toate etapele',
    CREATE_WITH_AI: 'Creează cu AI',
    ADD_LEARNING_CARD: 'Adaugă fișă de învățare',
    ELEMENTAR_CARD_PROGRESS: 'Progres card',
    VIEW_ANSWER: 'Vezi răspuns',
    ANSWER: 'Răspuns',
    WHAT_THINK_DIFFICULT_LEVEL_FOR_CARD: 'Care crezi că este gradul de dificultate a cardului?',
    EASY: 'Ușor',
    HARD: 'Greu',
    CONTINUE: 'Continuă',
    SEND_ANSWER: 'Trimite răspuns',
    FANTASTIC: 'Fantastic',
    YOU_ANSWERED_CORRECTLY: 'ai răspuns corect',
    WITH_REGRET: 'Cu regret',
    YOU_ANSWERED_WRONG: 'ai răspuns greșit',
    THE_CORRECT_ANSWER_IS: 'Răspunsul corect este:',
    MY_CARDS: 'Cardurile mele de învățare',
    FIELDS: {
      CATEGORY: 'Categorie',
      CATEGORY_NAME: 'Numele categoriei',
      TAG_NAME: 'Denumire (maxim 200 caractere)',
      DEPARTAMENT: 'Departamente',
      GROUP: 'Grupă',
      YOUR_URL: 'Your URL',
      VIDEO: 'Video',
      FILE: 'Incarcă pdf (doar unu)',
      LEARNING_STEP: 'Alege etapa de învățare',
      QUESTION_TITLE: 'Adaugă întrebarea',
      ANSWER: 'Adaugă răspunsul',
    },
  },
  CHAT: {
    START_NEW_CHAT: 'Începe o conversație nouă',
    MAIN_TITLE: 'Creare de conținut',
    TITLE: 'Asistent AI',
    NEW_CHAT: 'Conversație nouă',
    TYPE_HERE: 'Type here',
    SELECT_GPT: 'Create cards with GPT',
    SELECT_PDF: 'Create cards with PDF upload',
    UPLOAD_FILE: 'Upload a file',
    GPT_GENERATOR: 'GPT Generator',
    GENERATE_CARDS: 'Generate cards',
    MESSAGES_REMAIN: 'Mesaje rămase',
    FIELDS: {
      CONVERSATION_NAME: 'Conversation name',
    },
  },
  AFFILIATION: {
    TITLE_1: '1.Recompense imediate:',
    TITLE_2: '2.Adăugare utilizatori în grupe create:',
    TITLE_3: '3.Recomandări realizate prin link-uri:',
    TITLE_4: '4.Câștigă bani din subiectele create:',
    TEXT_1_1: 'Distribuind acest link, vei obține rapid și simplu un pachet suplimentar de 50 de mesaje și 20 de încercări pentru generare de conținut cu AI la fiecare persoană ce se înregistrează. ',
    TEXT_2_1: 'Invită elevii/studenții în grupurile tale de studiu introducând adresele lor de e-mail.',
    TEXT_2_2: 'Menține o conexiune directă, monitorizează progresul și urmărește statistici detaliate.',
    TEXT_2_3: 'Pentru fiecare elev dornic să învețe, vei beneficia de 10% din plățile efectuate de ei pentru materialele suplimentare.',
    TEXT_3_1: 'Invită-ți prietenii rapid și simplu pe orice rețea de socializare, în grupuri, mesaje directe și comentarii, folosind link-ul direct sau codul nostru QR.',
    TEXT_3_2: 'Cei dornici să învețe vor plăti pentru materiale suplimentare, iar tu vei beneficia de 10% din plățile efectuate de ei.',
    TEXT_3_3: 'Cei dornici să învețe vor plăti pentru materiale suplimentare, iar tu vei beneficia de 10% din plățile efectuate de ei.',
    TEXT_4_1: 'Ești interesat de a beneficia financiar din subiectele create de tine? Nu ezita să ne contactezi prin intermediul link-ului de mai jos, iar noi îți vom arăta cum poți fi plătit pentru fiecare accesare de către utilizatori.',
    RESIDENCE_TABLE: 'Tabel de exidență',
    MODAL: 'Modalități',
    NO_OF_USERS: 'Nr. de useri',
    NO_OF_PAYMENTS: 'Nr. de plăți',
    TOTAL_REVENUE: 'Total venituri',
    INCOME_AVAILABLE: 'Venituri disponibile',
    POSIBILITY_OF_USE: 'Posibilitate de utilizare',
    ADD_TO_GROUPS: 'Adaugă în grupe',
    SEND_THE_LINK: 'Trimite link-ul',
    WITHDRAW_MONEY: 'Retragere bani',
    MINIMUM_200: 'minim 200 euro',
    PAYMENT_FOR_1000_MESSAGES: 'Plată pentru o mie de mesaje : 9 euro',
    WITHDRAW: 'Retrage',
    PAY: 'Achită',
  },
  FILE_AI: {
    MAIN_TITLE: 'Listă de fișiere',
    FILE_NAME: 'File Name',
    QUESTIONS_LIST: 'Listă de întrebări',
    ADD_QUESTION: 'Adaugă întrebare',
    EDIT_QUESTION: 'Editează întrebare',
    SHARED: 'Distribuit',
    UNSHARED: 'Nedistribuit',
    LEARNING_CARDS_INFO: 'Voi crea fișe de învățare în baza documentului.',
    GENERATE_LEARNING_CARD: 'Generați carduri de învățare',
    GENERATING_LEARNING_CARDS: 'Se generează carduri de învățare...',
    GENERATING_LEARNING_CARDS_DESCRIPTION: 'Acest lucru va fi rapid! Vor fi gata pentru tine în curând.',
    CARDS_GENERATED_1: 'Am creat ',
    CARDS_GENERATED_2: ' de carduri de învățare:',
    ADD_LEARNING_CARD: 'Adaugă un card de învatare',
    PERCENT_SUCCESSFUL: 'Procent reusita Voice AI',
  },
};
module.exports = { locale };
