import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import { locale as ro } from '@/config/i18n/ro';
import { locale as en } from '@/config/i18n/en';

Vue.use(VueI18n);

let messages = {};

// get current selected language
const lang = window.localStorage.getItem('language');
messages = { ...messages, ro, en };

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages, // set locale messages
});

export default i18n;
