// action types

// mutation types

export default {
  state: {

  },
  getters: {

  },
  actions: {

  },
};
