import Vue from 'vue';
import Swal from 'sweetalert2';

const notify = ({ type = 'info', message = '' }) => {
  Swal.fire({
    position: 'top-end',
    icon: type,
    iconHtml: '<i class="fas fa-check-circle"></i>',
    title: message,
    toast: true,
    showConfirmButton: false,
    showCloseButton: true,
    closeButtonHtml: '<i class="fas fa-times"></i>',
    customClass: {
      container: 'st-notification-container',
      content: `st-notification-${type}`,
    },
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
};

Vue.prototype.$notify = notify;
Vue.notify = notify;

const Notify = (type, message) => {
  notify({ type, message });
};

export default Notify;
