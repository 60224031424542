// eslint-disable-next-line import/no-cycle
import { storeAsync } from '@/app-module';

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.unauth) {
      next();
      return;
    }
    const store = storeAsync();

    if (store.getters['auth/isAuthenticated']) {
      next('/');
    } else {
      next();
    }
  },
};
