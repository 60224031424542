// eslint-disable-next-line import/no-cycle
import unauthGuardMixin from './guards/unauth-guard-mixin';
import routes from './auth-routes';

const mixins = [
  unauthGuardMixin,
];

export default {
  routes,
  mixins,
};
