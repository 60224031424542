import routes from '@/shared/shared-routes';

import BaseButton from '@/shared/components/BaseButton.vue';

import store from '@/shared/shared-store';

export default {
  components: [
    BaseButton,
  ],
  filters: [],
  mixins: [],
  directives: [],
  routes,
  store,
};
