import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = window.VUE_APP_API_URL;
  },

  /**
       * Set the default HTTP request headers
       */
  setHeader() {
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
       * Send the GET HTTP request
       * @param resource
       * @param headers
       * @returns {*}
       */
  get(resource, headers) {
    return Vue.axios.get(resource, { headers });
  },

  /**
       * Set the POST HTTP request
       * @param resource
       * @param params
       * @param headers
       * @returns {*}
       */
  post(resource, params, headers) {
    return Vue.axios.post(resource, params, { headers });
  },

  /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
       * Send the PUT HTTP request
       * @param resource
       * @param params
       * @returns {IDBRequest<IDBValidKey> | Promise<void>}
       */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
       * Send the DELETE HTTP request
       * @param resource
       * @returns {*}
       */
  delete(resource) {
    return Vue.axios.delete(resource);
  },

  /**
       * Send the PATCH HTTP request
       * @param resource
       * @param params
       * @returns {IDBRequest<IDBValidKey> | Promise<void>}
       */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },
};

export default ApiService;
