import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';

// Vue 3rd party plugins
import i18n from '@/shared/plugins/vue-i18n';
import '@/shared/plugins/bootstrap-vue';

import { setupComponentsFiltersDirectivesAndMixins, storeAsync, routerAsync } from './app-module';

import App from './App.vue';

import ApiService from './shared/services/api.service';

// API service init
ApiService.init();

Vue.use(Router);
Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.use(Vuex);
setupComponentsFiltersDirectivesAndMixins();

new Vue({
  store: storeAsync(),
  router: routerAsync(),
  i18n,
  render: (h) => h(App),
}).$mount('#app');
