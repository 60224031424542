import Layout from '@/modules/layout/components/layout.vue';
import routes from '@/modules/layout/layout-routes';
import store from '@/modules/layout/layout-store';

export default {
  components: [Layout],
  filters: [],
  mixins: [],
  routes,
  store,
};
