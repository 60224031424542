/* eslint-disable import/no-unresolved */
const AuthLayout = () => import('@/modules/auth/components/AuthLayout.vue');
const Login = () => import('@/modules/auth/pages/Login.vue');
const ForgotPassword = () => import('@/modules/auth/pages/ForgotPassword.vue');
const CreateMentorAccount = () => import('@/modules/auth/pages/CreateMentorAccount.vue');
const CreateSchoolAccount = () => import('@/modules/auth/pages/CreateSchoolAccount.vue');
const GetStarted = () => import('@/modules/auth/pages/GetStarted.vue');
const CheckInbox = () => import('@/modules/auth/pages/CheckInbox.vue');
const CreatePassword = () => import('@/modules/auth/pages/CreatePassword.vue');
const CreatePasswordError = () => import('@/modules/auth/pages/CreatePasswordError.vue');
const CreatePasswordSucces = () => import('@/modules/auth/pages/CreatePasswordSucces.vue');

export default [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    meta: { unauth: true },
    children: [
      {
        name: 'login',
        path: '/auth/login',
        component: Login,
        meta: { unauth: true },
      },
      {
        name: 'forgotPassword',
        path: '/auth/forgot-password',
        component: ForgotPassword,
        meta: { unauth: true },
      },
      {
        name: 'checkInbox',
        path: '/auth/check-inbox',
        component: CheckInbox,
        meta: { unauth: true },
      },
      {
        name: 'createPassword',
        path: '/auth/create-password/:code',
        component: CreatePassword,
        meta: { unauth: true },
      },
      {
        name: 'errorCreatePassword',
        path: '/auth/create-password-error',
        component: CreatePasswordError,
        meta: { unauth: true },
      },
      {
        name: 'succesCreatePassword',
        path: '/auth/create-password-succes',
        component: CreatePasswordSucces,
        meta: { unauth: true },
      },
      {
        name: 'creareSchoolAccount',
        path: '/auth/create-school-account',
        component: CreateSchoolAccount,
        meta: { unauth: true },
      },
      {
        name: 'creareAccount',
        path: '/auth/create-account',
        component: CreateMentorAccount,
        meta: { unauth: true },
      },
    ],
  },
  {
    name: 'getStarted',
    path: '/auth/get-started',
    component: GetStarted,
    meta: { unauth: true },
  },
];
